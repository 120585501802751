const brandDetailsOnStageInitialStage = {
    brand: undefined,
    retailer: undefined,
    country: undefined,
    group: undefined,
    products: undefined,
    totalCount: undefined,
};

export default brandDetailsOnStageInitialStage;
