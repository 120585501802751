import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import BrandContext from './BrandContext';
import brandDetailsOnStageInitialState from './brandDetailsOnStageInitialState';

const initialState = {
    brandOnStage: undefined,
    setBrandOnStage: () => {},
    brandProductsOnStage: undefined,
    setBrandProductsOnStage: () => {},
    productTypeOnStage: undefined,
    setProductTypeOnStage: () => {},
    brandDetailsOnStage: {
        ...brandDetailsOnStageInitialState,
    },
    setBrandDetailsOnStage: () => {},
    searchFilterTypes: undefined,
    setSearchFilterTypes: () => {},
    showBrandSearchList: false,
    setShowBrandSearchList: () => {},
    productFilterList: undefined,
    setProductFilterList: () => {},
    productOnStage: undefined,
    setProductOnStage: () => {},
};

const actions = {
    SET_BRAND_ON_STAGE: 'SET_BRAND_ON_STAGE',
    SET_BRAND_PRODUCTS_ON_STAGE: 'SET_BRAND_PRODUCTS_ON_STAGE',
    SET_PRODUCT_TYPE_ON_STAGE: 'SET_PRODUCT_TYPE_ON_STAGE',
    SET_BRAND_DETAILS_ON_STAGE: 'SET_BRAND_DETAILS_ON_STAGE',
    SET_PRODUCT_FILTER_TYPES: 'SET_PRODUCT_FILTER_TYPES',
    SET_SHOW_BRAND_SEARCH_LIST: 'SET_SHOW_BRAND_SEARCH_LIST',
    SET_PRODUCT_FILTER_LIST: 'SET_PRODUCT_FILTER_LIST',
    SET_PRODUCT_ON_STAGE: 'SET_PRODUCT_ON_STAGE',
};

const reducer = (state, action) => {
    switch (action.type) {
        case actions.SET_BRAND_ON_STAGE:
            return { ...state, brandOnStage: action.brand };
        case actions.SET_BRAND_PRODUCTS_ON_STAGE:
            return { ...state, brandProductsOnStage: action.brandProducts };
        case actions.SET_PRODUCT_TYPE_ON_STAGE:
            return { ...state, productTypeOnStage: action.productType };
        case actions.SET_BRAND_DETAILS_ON_STAGE:
            return { ...state, brandDetailsOnStage: action.brandDetails };
        case actions.SET_PRODUCT_FILTER_TYPES:
            return { ...state, searchFilterTypes: action.searchFilterTypes };
        case actions.SET_SHOW_BRAND_SEARCH_LIST:
            return {
                ...state,
                showBrandSearchList: action.showBrandSearchList,
            };
        case actions.SET_PRODUCT_FILTER_LIST:
            return { ...state, productFilterList: action.productFilterList };
        case actions.SET_PRODUCT_ON_STAGE:
            return { ...state, productOnStage: action.productOnStage };
        default:
            return state;
    }
};

const BrandProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const value = {
        brandOnStage: state.brandOnStage,
        setBrandOnStage: (brand) => {
            dispatch({ type: actions.SET_BRAND_ON_STAGE, brand });
        },
        brandProductsOnStage: state.brandProductsOnStage,
        setBrandProductsOnStage: (brandProducts) => {
            dispatch({ type: actions.SET_BRAND_PRODUCTS_ON_STAGE, brandProducts });
        },
        productTypeOnStage: state.productTypeOnStage,
        setProductTypeOnStage: (productType) => {
            dispatch({ type: actions.SET_PRODUCT_TYPE_ON_STAGE, productType });
        },
        brandDetailsOnStage: state.brandDetailsOnStage,
        setBrandDetailsOnStage: ({ brand, retailer, country, group, products, totalCount }) => {
            dispatch({
                type: actions.SET_BRAND_DETAILS_ON_STAGE,
                brandDetails: { brand, retailer, country, group, products, totalCount },
            });
        },
        searchFilterTypes: state.searchFilterTypes,
        setSearchFilterTypes: (searchFilterTypes) => {
            dispatch({
                type: actions.SET_PRODUCT_FILTER_TYPES,
                searchFilterTypes,
            });
        },
        showBrandSearchList: state.showBrandSearchList,
        setShowBrandSearchList: (showBrandSearchList) => {
            dispatch({ type: actions.SET_SHOW_BRAND_SEARCH_LIST, showBrandSearchList });
        },
        productFilterList: state.productFilterList,
        setProductFilterList: (productFilterList) => {
            dispatch({ type: actions.SET_PRODUCT_FILTER_LIST, productFilterList });
        },
        productOnStage: state.productOnStage,
        setProductOnStage: (productOnStage) => {
            dispatch({ type: actions.SET_PRODUCT_ON_STAGE, productOnStage });
        },
    };

    return <BrandContext.Provider value={value}>{children}</BrandContext.Provider>;
};

BrandProvider.propTypes = {
    children: PropTypes.node,
};

BrandProvider.defaultProps = {
    children: undefined,
};

export default BrandProvider;
