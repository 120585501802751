import { gql } from '@apollo/client';

const GET_USER = gql`
    query GetUser($netlifyId: ID!) {
        findUserByNetlifyID(netlifyId: $netlifyId) {
            id: _id
            emailAddress
            netlifyId
            stripeId
            subscription
            subscriptionPlanId
            activeSubscription
            groups
            countries
        }
    }
`;

const UPDATE_USER = gql`
    mutation UpdateUser(
        $id: ID!
        $netlifyId: ID!
        $stripeId: ID!
        $subscription: String!
        $subscriptionPlanId: String!
        $activeSubscription: Boolean!
        $emailAddress: String!
        $groups: [String]!
        $countries: [String]!
    ) {
        updateUser(
            id: $id
            data: {
                netlifyId: $netlifyId
                stripeId: $stripeId
                subscription: $subscription
                subscriptionPlanId: $subscriptionPlanId
                activeSubscription: $activeSubscription
                emailAddress: $emailAddress
                groups: $groups
                countries: $countries
            }
        ) {
            emailAddress
            netlifyId
            stripeId
            subscription
            subscriptionPlanId
            activeSubscription
            groups
            countries
        }
    }
`;

// eslint-disable-next-line import/prefer-default-export
export { GET_USER, UPDATE_USER };
