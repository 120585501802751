import { css } from 'styled-components';
import { darken, rem } from 'polished';

const scrollbarStyles = () => css`
    &::-webkit-scrollbar {
        width: ${({ theme }) => rem(theme.site.brandSearch.scrollbar.width)};
    }

    &::-webkit-scrollbar-track {
        background-color: ${({ theme }) => darken(0.02, theme.site.stage.backgroundColor)};
        border-radius: ${({ theme }) => rem(theme.base.borderRadius.default)};
        margin-bottom: ${({ theme }) => rem(theme.site.brandSearch.scrollbar.offset)};
        margin-top: ${({ theme }) => rem(theme.site.brandSearch.scrollbar.offset)};
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${({ theme }) => theme.base.grays.grayLighter};
        border-radius: ${({ theme }) => rem(theme.base.borderRadius.default)};
    }
`;

export default scrollbarStyles;
