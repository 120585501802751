const siteTheme = {
    lightMode: {
        site: {
            stage: {
                backgroundColor: '#fcfcfc',
                paddingTop: 64,
            },
            viewport: {
                height: '90vh',
            },
            brandSearch: {
                overlay: {
                    color: 'rgba(0, 0, 0, 0.35)',
                },
                list: {
                    height: '60vh',
                    maxWidth: 600,
                },
                height: 86,
                spacing: 16,
                scrollbar: {
                    width: 8,
                    offset: 32,
                },
                card: {
                    backgroundColor: '#f2f2f2',
                },
            },
            brandOverview: {
                brandCard: {
                    maxWidth: 256,
                },
                retailer: {
                    containerWidth: {
                        xs: 200,
                        sm: 224,
                    },
                },
            },
            brandCountry: {
                retailer: {
                    containerWidth: {
                        xs: 200,
                        sm: 224,
                    },
                },
            },
            brandDetails: {
                closeIcon: {
                    size: 24,
                },
            },
            sort: {
                iconSize: 18,
                icon: {
                    highToLow: 'arrow-down-right',
                    lowToHigh: 'arrow-up-left',
                    discounted: 'percent',
                },
            },
            productItem: {
                oneColumn: {
                    gapV: 16,
                    gapH: 0,
                    width: 260,
                    height: 400,
                    gridMargin: 32,
                },
                multiColumn: {
                    gapV: 16,
                    gapH: 16,
                    width: 260,
                    height: 400,
                    gridMargin: 32,
                },
                image: {
                    width: '100%',
                },
            },
            productFilters: {
                searchInput: {
                    width: 150,
                },
                searchReset: {
                    width: 50,
                },
            },
            brandRankings: {
                card: {
                    paddingV: 20,
                    borderWidth: 1,
                    height: 80,
                },
            },
            brandTitle: {
                fontWeight: 500,
                uppercase: true,
            },
            categoryTitle: {
                fontWeight: 500,
                uppercase: false,
            },
            retailerLogo: {
                height: 20,
                opacity: {
                    default: 1,
                    muted: 0.7,
                },
            },
            marketing: {
                inventoryCard: {
                    maxWidth: 440,
                    padding: 32,
                },
            },
            footer: {
                twitter: {
                    iconColor: '#666666',
                },
            },
        },
    },
    darkMode: {
        site: {
            stage: {
                backgroundColor: '#262626',
                paddingTop: 64,
            },
            viewport: {
                height: '90vh',
            },
            brandSearch: {
                overlay: {
                    color: 'rgba(0, 0, 0, 0.75)',
                },
                list: {
                    height: '60vh',
                    maxWidth: 600,
                },
                height: 86,
                spacing: 16,
                scrollbar: {
                    width: 8,
                    offset: 32,
                },
                card: {
                    backgroundColor: '#333333',
                },
            },
            brandOverview: {
                brandCard: {
                    maxWidth: 256,
                },
                retailer: {
                    containerWidth: {
                        xs: 200,
                        sm: 224,
                    },
                },
            },
            brandCountry: {
                retailer: {
                    containerWidth: {
                        xs: 200,
                        sm: 224,
                    },
                },
            },
            brandDetails: {
                closeIcon: {
                    size: 24,
                },
            },
            sort: {
                iconSize: 18,
                icon: {
                    highToLow: 'arrow-down-right',
                    lowToHigh: 'arrow-up-left',
                    discounted: 'percent',
                },
            },
            productItem: {
                oneColumn: {
                    gapV: 16,
                    gapH: 0,
                    width: 260,
                    height: 400,
                    gridMargin: 32,
                },
                multiColumn: {
                    gapV: 16,
                    gapH: 16,
                    width: 260,
                    height: 400,
                    gridMargin: 32,
                },
                image: {
                    width: '100%',
                },
            },
            productFilters: {
                searchInput: {
                    width: 150,
                },
                searchReset: {
                    width: 50,
                },
            },
            brandRankings: {
                card: {
                    paddingV: 20,
                    borderWidth: 1,
                    height: 80,
                },
            },
            brandTitle: {
                fontWeight: 500,
                uppercase: true,
            },
            categoryTitle: {
                fontWeight: 500,
                uppercase: false,
            },
            retailerLogo: {
                height: 20,
                opacity: {
                    default: 1,
                    muted: 0.7,
                },
            },
            marketing: {
                inventoryCard: {
                    maxWidth: 440,
                    padding: 32,
                },
            },
            footer: {
                twitter: {
                    iconColor: '#cacaca',
                },
            },
        },
    },
};

export default siteTheme;
