import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { useUser } from '@luigiclaudio/ga-auth-theme';
import useAllBrand from '../../../services/useAllBrand';
import BrandSearchList from '../BrandSearchList';
import BrandSearch from '../BrandSearch';
import locationPropTypes from '../../../prop-types/locationPropTypes';
import brandUtils from '../../../utils/brandUtils';
import appConfig from '../../../data/appConfig.json';
import useAppUser from '../../../services/useAppUser';

const BrandSearchResults = ({ location }) => {
    const [user] = useUser();
    const brandList = useAllBrand();
    const { groups, countries } = appConfig;

    const defaultBrandList = brandUtils.brandListByUserPreferences({
        brandList,
        activeGroups: groups,
        activeCountries: countries,
    });

    const [brandListByUser, setBrandListByUser] = useState(defaultBrandList);
    const { userActiveGroups, userActiveCountries } = useAppUser();

    useEffect(() => {
        if (userActiveGroups && userActiveCountries) {
            setBrandListByUser(
                brandUtils.brandListByUserPreferences({
                    brandList,
                    activeGroups: userActiveGroups,
                    activeCountries: userActiveCountries,
                }),
            );
        }
    }, [userActiveGroups, userActiveCountries]);

    useEffect(() => {
        if (!user) {
            setBrandListByUser(defaultBrandList);
        }
    }, [user]);

    const initialValues = {
        brandFinder: '',
    };

    return (
        <Formik
            initialValues={initialValues}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={(_, { resetForm }) => {
                setTimeout(() => {
                    resetForm(initialValues);
                }, 350);
            }}
        >
            {() => (
                <Form noValidate>
                    <BrandSearch amount={brandListByUser.length} />
                    <BrandSearchList list={brandListByUser} location={location} />
                </Form>
            )}
        </Formik>
    );
};

BrandSearchResults.propTypes = {
    location: locationPropTypes.location,
};

BrandSearchResults.defaultProps = {
    location: undefined,
};

export default BrandSearchResults;
