import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import withSizes from 'react-sizes';
import { isSizes } from '@luigiclaudio/ga-baseline-ui/layout';
import { Header, HeaderOffset } from '@luigiclaudio/ga-baseline-ui/header';
import { useUser, Identity } from '@luigiclaudio/ga-auth-theme';
import {
    useHasMounted,
    Loader,
    useLoading,
    SiteMetadata,
} from '@luigiclaudio/ga-baseline-ui/helpers';
import { ThemeModeContext } from '@luigiclaudio/ga-baseline-ui/theme-provider';
import BrandSearchResults from '../app/BrandSearchResults';
import BrandSearchResultsOffset from '../app/BrandSearchResults/BrandSearchResultsOffset';
import locationPropTypes from '../../prop-types/locationPropTypes';
import BrandContext from '../context/BrandContext';

const SiteHeader = ({ location, children, isMobile }) => {
    const intl = useIntl();
    const [user, isLoadingUser] = useUser();
    const hasMounted = useHasMounted();
    const { logoutUser } = Identity.useIdentityContext();
    const [isLoading, load] = useLoading();
    const theme = useContext(ThemeContext);
    const { themeMode, toggleThemeMode } = useContext(ThemeModeContext);

    let isDarkModeOn = false;

    if (themeMode === 'lightMode') {
        isDarkModeOn = true;
    }

    const { setShowBrandSearchList } = useContext(BrandContext);

    const navigationThemeMode = {
        label: intl.formatMessage({
            id: isDarkModeOn
                ? 'siteHeader.darkMode.button.label'
                : 'siteHeader.lightMode.button.label',
        }),
        icon: isDarkModeOn ? 'moon' : 'sun',
        onClick: () => toggleThemeMode(),
        btnType: 'link',
        skin: 'muted',
        showLabel: !!isMobile,
    };

    const navigation = {
        public: [
            {
                label: intl.formatMessage({ id: 'siteHeader.home.button.label' }),
                btnType: 'link',
                skin: 'muted',
                path: '/',
                icon: 'home',
            },
            {
                label: intl.formatMessage({ id: 'siteHeader.brandRankings.button.label' }),
                btnType: 'link',
                skin: 'muted',
                path: '/brandRankings',
                icon: 'layers',
            },
            {
                label: intl.formatMessage({ id: 'siteHeader.categories.button.label' }),
                btnType: 'link',
                skin: 'muted',
                path: '/categories',
                icon: 'grid',
            },
            {
                label: intl.formatMessage({ id: 'siteHeader.signIn.button.label' }),
                btnType: 'link',
                skin: 'muted',
                path: '/signIn',
                icon: 'user-check',
            },
            {
                label: intl.formatMessage({ id: 'siteHeader.tryForFree.button.label' }),
                btnType: 'link',
                skin: 'success',
                path: '/signUp',
                icon: 'user-plus',
            },
            navigationThemeMode,
        ],
        client: [
            {
                label: intl.formatMessage({ id: 'siteHeader.dashboard.button.label' }),
                btnType: 'link',
                skin: 'muted',
                path: '/app',
                icon: 'server',
            },
            {
                label: intl.formatMessage({ id: 'siteHeader.brandRankings.button.label' }),
                btnType: 'link',
                skin: 'muted',
                path: '/brandRankings',
                icon: 'layers',
            },
            {
                label: intl.formatMessage({ id: 'siteHeader.categories.button.label' }),
                btnType: 'link',
                skin: 'muted',
                path: '/categories',
                icon: 'grid',
            },
            {
                label: intl.formatMessage({ id: 'siteHeader.account.button.label' }),
                btnType: 'link',
                skin: 'muted',
                path: '/app/account',
                icon: 'user-check',
            },
            navigationThemeMode,
        ],
    };

    const menuModalSecondaryListSignOut = {
        label: intl.formatMessage({ id: 'siteHeader.signOut.button.label' }),
        btnType: 'outlined',
        skin: 'muted',
        icon: 'log-out',
        loading: isLoading,
        onClickDismiss: true,
        onClick: () =>
            load(logoutUser())
                .then((res) => {
                    return res;
                })
                .catch((err) => {
                    throw err;
                }),
    };

    const navigationMenuModal = {
        public: [
            {
                label: intl.formatMessage({ id: 'siteHeader.signIn.button.label' }),
                path: '/signIn',
                btnType: 'outlined',
                skin: 'muted',
                icon: 'user',
                onClickDismiss: true,
            },
        ],
    };

    const renderBgColor = hasMounted ? theme.site.stage.backgroundColor : null;

    return (
        <>
            <SiteMetadata
                canonical={
                    location && location.pathname
                        ? location.pathname
                        : process.env.GATSBY_SITE_BASE_URL
                }
                siteName={intl.formatMessage({ id: 'siteName' })}
                siteUrl={process.env.GATSBY_SITE_BASE_URL}
                siteDescription={intl.formatMessage({ id: 'home.intro.subtitle' })}
                title={intl.formatMessage({ id: 'home.intro.title' })}
                keywords={intl.formatMessage({ id: 'siteKeywords' })}
                twitterHandle={intl.formatMessage({ id: 'twitterHandle' })}
                siteIcon="/assets/common/logo-dark.png"
            />
            <Header
                navigationList={user && hasMounted ? navigation.client : navigation.public}
                menuModalSecondaryList={
                    user && hasMounted
                        ? [menuModalSecondaryListSignOut]
                        : navigationMenuModal.public
                }
                onClickLinkCallback={() => {
                    setShowBrandSearchList(false);
                }}
                logoPath="/assets/common/logo-dark.svg"
                logoPathAlt="/assets/common/logo-light.svg"
                logoWidth={190}
                currentPath={location?.pathname}
            />

            <HeaderOffset isFullHeight bgStart={renderBgColor}>
                {isLoadingUser && <Loader isFullScreen fullScreenBackgroundColor={renderBgColor} />}
                {hasMounted && <BrandSearchResults location={location} />}
                <BrandSearchResultsOffset>{children}</BrandSearchResultsOffset>
            </HeaderOffset>
        </>
    );
};

const mapSizesToProps = ({ width }, { theme }) => {
    return {
        isMobile: isSizes.mdView({ width }, { theme }),
    };
};

SiteHeader.propTypes = {
    children: PropTypes.node,
    location: locationPropTypes.location,
    isMobile: PropTypes.bool,
};

SiteHeader.defaultProps = {
    location: undefined,
    children: undefined,
    isMobile: false,
};

export default withSizes(mapSizesToProps)(SiteHeader);
