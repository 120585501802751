import { isEmpty } from 'lodash';
import slugify from 'slugify';
import appConfig from '../data/appConfig.json';

const brandUtils = {
    groupBy: (objectArray, property) => {
        return objectArray.reduce((acc, obj) => {
            const key = obj[property];
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        }, {});
    },
    productByProductTypeOnStage: (products, productTypeOnStage) => {
        let hits = products;
        const splitProductTypeOnStage = [];

        if (productTypeOnStage) {
            productTypeOnStage.forEach((productType) => {
                const splitProductType = productType.split('-');
                splitProductTypeOnStage.push(...splitProductType);
            });

            hits = products.filter((item) => {
                let selected = true;

                for (let i = 0; i < productTypeOnStage.length; i += 1) {
                    if (item.intlKeywords.indexOf(productTypeOnStage[i]) === -1) {
                        selected = false;
                    }
                }

                if (
                    splitProductTypeOnStage.every(
                        (productType) => item.intlKeywords.indexOf(productType) >= 0,
                    )
                ) {
                    selected = true;
                }

                return selected;
            });
        }
        return hits;
    },

    sortProductByCurrentPriceHighToLow: (products) =>
        [...products].sort((a, b) => b.currentLocalPrice - a.currentLocalPrice),

    sortProductByCurrentPriceLowToHigh: (products) =>
        [...products].sort((a, b) => a.currentLocalPrice - b.currentLocalPrice),

    sortBrandsByProductsAmountHightToLow: (brandList) =>
        [...brandList].sort((a, b) => b.totalCount - a.totalCount),

    sortBrandsByProductsAmountLowToHigh: (brandList) =>
        [...brandList].sort((a, b) => a.totalCount - b.totalCount),

    priceFormatOptions: (currency) => {
        return { currencyDisplay: 'symbol', style: 'currency', currency, minimumFractionDigits: 2 };
    },

    retailersByUserPreferences: ({ retailers, activeGroups, activeCountries }) => {
        const retailersListByUserPreferences = [];
        const { groups: defaultGroups, countries: defaultCountries } = appConfig;

        const setGroups = [];
        const setCountries = [];

        if (isEmpty(activeGroups)) {
            setGroups.push(...defaultGroups);
        } else {
            setGroups.push(...activeGroups);
        }

        if (isEmpty(activeCountries)) {
            setCountries.push(...defaultCountries);
        } else {
            setCountries.push(...activeCountries);
        }

        retailers.forEach((retailer) => {
            const { retailer: currentRetailer, productsByCountry } = retailer;

            const results = productsByCountry.filter((country) => {
                return country.products.some((product) => {
                    return setGroups.includes(product.group);
                });
            });

            if (results && results.length > 0) {
                const filteredProductsByGroups = results.map((result) => {
                    const filteredProductsResults = result.products.filter((item) => {
                        return setGroups.includes(item.group);
                    });

                    return {
                        ...result,
                        products: filteredProductsResults,
                    };
                });

                const filteredProductsByGroupsByCountries = filteredProductsByGroups.filter(
                    (result) => {
                        return setCountries.includes(result.country);
                    },
                );

                if (
                    filteredProductsByGroupsByCountries &&
                    filteredProductsByGroupsByCountries.length > 0
                ) {
                    retailersListByUserPreferences.push({
                        retailer: currentRetailer,
                        productsByCountry: filteredProductsByGroupsByCountries,
                    });
                }
            }
        });

        return retailersListByUserPreferences;
    },

    brandListByUserPreferences: ({ brandList, activeGroups, activeCountries }) => {
        const { groups: defaultGroups, countries: defaultCountries } = appConfig;

        const setGroups = [];
        const setCountries = [];

        if (isEmpty(activeGroups)) {
            setGroups.push(...defaultGroups);
        } else {
            setGroups.push(...activeGroups);
        }

        if (isEmpty(activeCountries)) {
            setCountries.push(...defaultCountries);
        } else {
            setCountries.push(...activeCountries);
        }

        const brandListByAvailableGroups = brandList.filter((item) => {
            return item.availableGroups.some((group) => setGroups.includes(group));
        });

        const availableBrandList = brandListByAvailableGroups.filter((item) => {
            return item.availableCountries.some((country) => setCountries.includes(country));
        });

        const brandListResults = [];

        availableBrandList.forEach((item) => {
            const { brand, groupDetails } = item;

            const availableCountriesByUserPreferences = [];
            const availableRetailersByUserPreferences = [];
            let productsTotalCountByUserPreferences = 0;

            setGroups.forEach((group) => {
                const getDetailsCountries =
                    groupDetails
                        .find((details) => details.group === group)
                        ?.countries.filter((country) => setCountries.includes(country)) || [];

                groupDetails
                    .find((details) => details.group === group)
                    ?.retailersByCountries.forEach((retailerByCountries) => {
                        if (setCountries.includes(retailerByCountries.country)) {
                            availableRetailersByUserPreferences.push(
                                ...retailerByCountries.retailers,
                            );
                            productsTotalCountByUserPreferences += retailerByCountries.totalCount;
                        }
                    });

                availableCountriesByUserPreferences.push(...getDetailsCountries);
            });

            if (!isEmpty(availableRetailersByUserPreferences)) {
                brandListResults.push({
                    title: brand,
                    to: slugify(brand, { lower: true, strict: true }),
                    tags: `${brand}, ${brand.toLowerCase()}, ${brand
                        .split(' ')
                        .join(', ')}, ${brand.toLowerCase().split(' ').join(', ')}`,
                    retailersAmount: [...new Set(availableRetailersByUserPreferences)].length,
                    availableCountries: [...new Set(availableCountriesByUserPreferences)],
                    totalCount: productsTotalCountByUserPreferences,
                });
            }
        });

        return brandListResults;
    },

    productsByCountryByUserPreferences: ({ productsByRetailer, activeGroups }) => {
        const { groups: defaultGroups } = appConfig;

        const setGroups = [];

        if (isEmpty(activeGroups)) {
            setGroups.push(...defaultGroups);
        } else {
            setGroups.push(...activeGroups);
        }

        const results = productsByRetailer.filter((product) => setGroups.includes(product.group));
        return results;
        // const results = productsByRetailer.filter((retailer) => {
        //     const { products } = retailer;

        //     return products.some((product) => {
        //         return setGroups.includes(product.group);
        //     });
        // });

        // const productsByRetailerByUserPreferences = results.map((result) => {
        //     const { products } = result;
        //     const filteredProductsByGroup = products.filter((item) => {
        //         return setGroups.includes(item.group);
        //     });
        //     return {
        //         ...result,
        //         products: filteredProductsByGroup,
        //     };
        // });

        // return productsByRetailerByUserPreferences;
    },

    groupLabel: (groupType) => {
        const groupTypeLabel = [
            {
                type: 'women',
                message: 'brand.group.women',
            },
            {
                type: 'men',
                message: 'brand.group.men',
            },
        ];

        const label = groupTypeLabel.find((group) => group.type === groupType);

        return label.message;
    },

    brandApi: async (brand, country = null) => {
        let response;
        let data;

        if (country) {
            response = await fetch(
                `/api/brands/brand-${brand}-country-${country}.json`,
            ).then((res) => res.json());
            data = response.country;
        } else {
            response = await fetch(`/api/brands/brand-${brand}-retailers.json`).then((res) =>
                res.json(),
            );
            data = response;
        }

        return data;
    },

    brandBriefsDetails: (retailerList, group) => {
        const briefsDetails = [
            {
                highValue: null,
                retailer: null,
                group: null,
                lowValue: null,
                currentCount: null,
            },
        ];

        const allAvgValue = [];

        retailerList.forEach((item) => {
            if (item && Object.prototype.hasOwnProperty.call(item, group)) {
                const { retailer } = item;
                briefsDetails.push({
                    retailer,
                    highValue: item[group].briefs.highPrice,
                    lowValue: item[group].briefs.lowPrice,
                    group,
                    currentCount: item[group].briefs.currentCount,
                });

                allAvgValue.push(item[group].briefs.avgPrice);
            }
        });

        const filterBriefsDetails = briefsDetails.filter((item) => item.currentCount);

        const sortListPriceHighToLow = [...filterBriefsDetails].sort(
            (a, b) => b.highValue - a.highValue,
        );

        const sortListTotalCountHighToLow = [...filterBriefsDetails].sort(
            (a, b) => b.currentCount - a.currentCount,
        );

        const avgPriceValue = allAvgValue.reduce((a, b) => a + b, 0) / allAvgValue.length;

        let highPriceRetailer;
        let highPriceValue;

        let retailerBriefsGroups;

        let lowPriceRetailer;
        let lowPriceValue;

        let highTotalCountRetailer;
        let highTotalCountValue;
        let lowTotalCountRetailer;
        let lowTotalCountValue;

        if (!isEmpty(filterBriefsDetails)) {
            const { group: isRetailerBriefsGroup } = filterBriefsDetails[0];

            const {
                retailer: isHighPriceRetailer,
                highValue: isHighPriceValue,
            } = sortListPriceHighToLow[0];

            const {
                retailer: isLowPriceRetailer,
                lowValue: isLowPriceValue,
            } = sortListPriceHighToLow[sortListPriceHighToLow.length - 1];

            const {
                retailer: isHighTotalCountRetailer,
                currentCount: isHighTotalCountValue,
            } = sortListTotalCountHighToLow[0];

            const {
                retailer: isLowTotalCountRetailer,
                currentCount: isLowTotalCountValue,
            } = sortListTotalCountHighToLow[sortListTotalCountHighToLow.length - 1];

            highPriceRetailer = isHighPriceRetailer;
            highPriceValue = isHighPriceValue;

            lowPriceRetailer = isLowPriceRetailer;
            lowPriceValue = isLowPriceValue;

            highTotalCountRetailer = isHighTotalCountRetailer;
            highTotalCountValue = isHighTotalCountValue;

            lowTotalCountRetailer = isLowTotalCountRetailer;
            lowTotalCountValue = isLowTotalCountValue;

            retailerBriefsGroups = isRetailerBriefsGroup;
        }

        return {
            avgPriceValue,
            highPriceValue,
            highPriceRetailer,
            lowPriceValue,
            lowPriceRetailer,
            highTotalCountRetailer,
            highTotalCountValue,
            lowTotalCountRetailer,
            lowTotalCountValue,
            retailerBriefsGroups,
        };
    },

    getBrandSlug: (brand) => slugify(brand, { lower: true, strict: true }),

    getPathToBrand: (brand, country) =>
        `${slugify(brand, { lower: true, strict: true })}-${country}`,
};

export default brandUtils;
