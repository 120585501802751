import { useQuery } from '@apollo/client';
import { useUser } from '@luigiclaudio/ga-auth-theme';
import { GET_USER } from './actionTypes';

const useAppUser = (onCompletedQuery = null) => {
    const [user] = useUser();

    const {
        loading: loadingSubscription,
        error: errorSubscription,
        data,
        refetch: refetchSubscription,
    } = useQuery(GET_USER, {
        variables: { netlifyId: user?.id },
        onCompleted: onCompletedQuery,
    });

    const isPremium = data?.findUserByNetlifyID.subscription === 'premium';
    const isFree = data?.findUserByNetlifyID.subscription === 'free';
    const subscriptionType = data?.findUserByNetlifyID.subscription;
    const groups = data?.findUserByNetlifyID.groups;
    const countries = data?.findUserByNetlifyID.countries;
    const username = user?.user_metadata.full_name;
    const isActive = data?.findUserByNetlifyID.activeSubscription;

    return {
        isLoadingSubscription: loadingSubscription,
        isErrorSubscription: errorSubscription,
        isPremiumSubscription: isPremium,
        isFreeSubscription: isFree,
        isActiveSubscription: isActive,
        subscriptionType,
        userActiveGroups: groups,
        userActiveCountries: countries,
        username,
        refetchSubscription,
    };
};

export default useAppUser;
